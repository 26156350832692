import React, { useState } from 'react';
import Layout from '../components/layout';
import OpeningTimeTable from '../components/OpeningTimeTable';
import Column from '../components/Column';
import Img from 'gatsby-image';
import Grid from '../components/Grid';
import SectionTitle from '../components/SectionTitle';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const Praxis = ({ data, location, ...props }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [index, setIndex] = useState(0);

	const onClickImage = (image, index) => () => {
		setIndex(index);
		setIsOpen(true);
	};

	const images = data.images.edges.map((edge) => {
		return edge.node.childImageSharp.full.src;
	});

	return (
		<Layout location={location} title="Praxis" noSlider {...props}>
			<p>
				Direkt im Zentrum Kassels, in der Kölnischen Straße 5, befindet
				sich unsere Praxis. In der Nähe der Kurfürstengalerie und
				gegenüber des ehemaligen Henschelhauses gelegen, beraten und
				behandeln wir hier unsere Patienten in heller und Ruhe gebender,
				freundlicher Atmosphäre.
			</p>
			<div className="my-8">
				{isOpen && (
					<Lightbox
						mainSrc={images[index]}
						nextSrc={images[(index + 1) % images.length]}
						prevSrc={
							images[(index + images.length - 1) % images.length]
						}
						onCloseRequest={() => setIsOpen(false)}
						onMovePrevRequest={() =>
							setIndex(
								(index + images.length - 1) % images.length,
							)
						}
						onMoveNextRequest={() =>
							setIndex((index + 1) % images.length)
						}
					/>
				)}
				<Grid>
					{data.images.edges.map((image, index) => {
						return (
							<Column
								key={image.node.name}
								md="1/3"
								className="mt-6"
							>
								<div
									className="cursor-pointer"
									onClick={onClickImage(image, index)}
								>
									<Img
										fluid={image.node.childImageSharp.fluid}
									/>
								</div>
							</Column>
						);
					})}
				</Grid>
			</div>
			<p>
				<strong>
					Telefonisch können Sie uns zu folgenden Zeiten erreichen:
				</strong>
			</p>
			<OpeningTimeTable />

			<p>
				oder nutzen Sie unser Kontaktformular, wir rufen Sie gerne
				zurück.
			</p>
		</Layout>
	);
};

export const query = graphql`
	query PraxisQuery {
		images: allFile(filter: { relativePath: { glob: "praxis/*" } }) {
			edges {
				node {
					name
					childImageSharp {
						full: fluid(maxWidth: 1920) {
							...GatsbyImageSharpFluid
						}
						fluid(maxWidth: 240, maxHeight: 160) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		}
	}
`;

export default Praxis;
